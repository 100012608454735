/**
 * One Choice for Change homepage scripts
 * @license
 * Copyright 2023 Four Mortals Productions, Inc. All rights reserved.
 * @version 24.1.0
 * Module history:
 *    1.0.0 - initial build
 *    2.0.0 - Convert to class
 *          - Replace OC4CPage with OC4CPage
 *    4.0.0 - @material 13.0, lit 2.0
 *    4.1.0 - Use external top app bar and nav bar
 *   14.4.5 - Include MDC buttton, email, phone links
 *   24.1.0 - Use @material/web components (replace MDC)
 * @since November 2019
 * @author Earl Rogers
 */

'use strict';
// eslint-disable-next-line no-unused-vars -- lit element
// import {FMPLogo} from '@fourmortals/theme/fmp-logo.js';

export class OC4CHome {
  static buildEmailLink(addr) {
    const link = document.createElement('a');
    link.href = `mailto:${addr}`;
    const body = document.querySelector('body');
    body.appendChild(link);
    return link;
  }
  static buildTelephoneLink(number) {
    const link = document.createElement('a');
    link.href = `tel:${number}`;
    const body = document.querySelector('body');
    body.appendChild(link);
    return link;
  }
  static get EMAIL_SELECTOR() {
    return 'oc4c-contact__address--email';
  }
  static get TELEPHONE_SELECTOR() {
    return 'oc4c-contact__address--telephone';
  }

  constructor() {
    this.deferredHash = location.hash ? location.hash : null;
    if (this.deferredHash) {
      history.replaceState({}, document.title, location.pathname);
    }
    this.initialize_();
    if (this.deferredHash) {
      location.href = this.deferredHash;
    }
  }
  initialize_() {
    /*
    const el = document.querySelector('.fmp-logo-button');
    if (el) {
      el.addEventListener('click', (event) => {
        location.href = 'https://fourmortals.com';
      });
    }
    */
    const els = [...document.querySelectorAll('.oc4c-contact__address--link')];
    for (const el of els) {
      el.addEventListener('click', (event) => {
        event.preventDefault();
        const el = event.currentTarget.children[1];
        if (el.classList.contains(this.constructor.EMAIL_SELECTOR)) {
          if (!this.emailLink) {
            this.emailLink = this.constructor.buildEmailLink(el.textContent);
          }
          this.emailLink.click();
        } else {
          if (!this.telephoneLink) {
            this.telephoneLink =
              this.constructor.buildTelephoneLink(el.textContent);
          }
          this.telephoneLink.click();
        }
      });
    }
  }
}
